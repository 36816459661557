import currency from 'currency.js';

export function getCurrencyWithoutDecimal(value: number | string) {
  let numberValue = value;

  if (typeof value !== 'number') {
    const parsedValue = Number.parseFloat(value);
    if (Number.isNaN(parsedValue)) {
      return value;
    }
    numberValue = parsedValue;
  }

  return currency(numberValue, {
    precision: 0,
  }).format();
}
